import {firstYearOfBusiness} from '../constants';
import {formatCurrency} from '../formatters';
import {gql} from '../graphql';
import {useExpenseTransactions, useIncomeTransactions} from '../graphql/transaction-hooks';
import {getTransactionsTotal} from '../helpers/transactions';
import {useQuery} from '@apollo/client';
import {Box, Text} from '@sproutsocial/racine';
import {DateTime} from 'luxon';
import {useMemo} from 'react';
import {range} from 'shared/src/util';

const GET_ACCOUNTS = gql(/* GraphQL */ `
	query GetDashboardAccounts {
		accounts {
			id
			name
			balance
			closed
		}
	}
`);

export default function Dashboard() {
	const {data: {accounts} = {}} = useQuery(GET_ACCOUNTS);
	const operatingCapital =
		accounts?.reduce((operatingCapital, account) => {
			return operatingCapital + account.balance;
		}, 0) ?? 0;

	const validYears = range(DateTime.now().year, firstYearOfBusiness - 1, -1);

	return (
		<Box p='space.400'>
			<Box
				display='flex'
				flexDirection='column'
				border='500'
				borderRadius='outer'
				borderColor='container.border.base'
				bg='white'
				mb='space.400'
			>
				<Box p='space.300'>
					<Box display='flex' justifyContent='space-between'>
						<Text fontSize='300' fontWeight='semibold'>
							Operating Capital
						</Text>
						<Text fontSize='300' fontWeight='semibold'>
							{operatingCapital === null ? '–' : formatCurrency(operatingCapital)}
						</Text>
					</Box>
				</Box>
				{accounts
					?.filter((account) => !account.closed)
					.map((account) => (
						<Box key={account.id} p='space.300' borderTop='500' borderColor='container.border.base'>
							<Box display='flex' justifyContent='space-between'>
								<Text fontSize='200' mr='space.300'>
									{account.name}
								</Text>
								<Text fontSize='200'>{formatCurrency(account.balance)}</Text>
							</Box>
						</Box>
					))}
			</Box>

			{validYears.map((year) => (
				<YearlyProfitWidget key={year} year={year} />
			))}
		</Box>
	);
}

const YearlyProfitWidget = ({year}: {year: number}) => {
	const startDate = DateTime.fromISO(`${year}-01-01`);
	const endDate = DateTime.fromISO(`${year}-12-31`);

	const {transactions: incomeTransactions} = useIncomeTransactions({startDate, endDate});
	const income = useMemo(
		() => getTransactionsTotal(incomeTransactions ?? []),
		[incomeTransactions],
	);

	const {transactions: expenseTransactions} = useExpenseTransactions({startDate, endDate});
	const expenses = useMemo(
		() => getTransactionsTotal(expenseTransactions ?? []),
		[expenseTransactions],
	);

	return (
		<Box
			display='flex'
			flexDirection='column'
			border='500'
			borderRadius='outer'
			borderColor='container.border.base'
			bg='white'
			mb='space.400'
		>
			<Box p='space.300'>
				<Box display='flex' justifyContent='space-between'>
					<Text fontSize='300' fontWeight='semibold'>
						{year} Profit
					</Text>
					<Text fontSize='300' fontWeight='semibold'>
						{formatCurrency(income + expenses)}
					</Text>
				</Box>
			</Box>
			<Box p='space.300' borderTop='500' borderColor='container.border.base'>
				<Box display='flex' justifyContent='space-between'>
					<Text fontSize='200' mr='space.300'>
						Income
					</Text>
					<Text fontSize='200'>{formatCurrency(income)}</Text>
				</Box>
			</Box>
			<Box p='space.300' borderTop='500' borderColor='container.border.base'>
				<Box display='flex' justifyContent='space-between'>
					<Text fontSize='200' mr='space.300'>
						Expenses
					</Text>
					<Text fontSize='200'>{formatCurrency(Math.abs(expenses))}</Text>
				</Box>
			</Box>
		</Box>
	);
};
